<template>
    <div>
      <b-card>
        <!-- SWITCH BUTTON MEDIA KONVENSIONAL IS ACTIVE -->
        <div class="d-flex" v-if="media === 'konvensional'">
          <b-button
            variant="primary"
            class="mr-2"
            @click="media = 'konvensional'"
          >
            Media Konvensional
          </b-button>
          <b-button variant="flat" @click="media = 'sosial'">
            Media Sosial
          </b-button>
        </div>
  
        <!-- SWITCH BUTTON MEDIA SOSIAL IS ACTIVE -->
        <div class="d-flex" v-else-if="media === 'sosial'">
          <b-button
            variant="flat"
            class="mr-2"
            @click="media = 'konvensional'"
          >
            Media Konvensional
          </b-button>
          <b-button variant="primary" @click="media = 'sosial'">
            Media Sosial
          </b-button>
        </div>
      </b-card>
  
      <!-- CONTENT OF MEDIA KONVENSIONAL -->
      <div v-if="media === 'konvensional'">
        <cardMedkon />
        <trenGroupTopik />
        <cardSentimen />
        <cardTopikMedkon />
        <cardStasiunTV />
        <programStasiunTV />
        <cardMediaCetak />
        <cardEmotion />
      </div>
  
      <!-- CONTENT OF MEDIA SOSIAL -->
      <div v-else-if="media === 'sosial'">
        <cardMedsos />
        <cardChannelMedsos />
        <cardSentimenMedsos />
        <cardTopikMedsos />
        <cardEmotionMedsos />
        <div class="row" style="margin-bottom:2rem">
          <div class="col-md-6">
            <cardHashtagMedsos />
          </div>
          <div class="col-md-6">
            <cardPeakTime />
          </div>
        </div>
        <cardStasiun />
      </div>
    </div>
  </template>
  <script>
  import { BCard, BButton } from "bootstrap-vue";
  import cardMedkon from "@/components/analytic/general/media-konvensional/cardMedkon.vue";
  import trenGroupTopik from "@/components/analytic/general/media-konvensional/trenGroupTopik.vue";
  import cardSentimen from "@/components/analytic/general/media-konvensional/cardSentimen.vue";
  import cardTopikMedkon from "@/components/analytic/general/media-konvensional/cardTopikMedkon.vue";
  import cardStasiunTV from "@/components/analytic/general/media-konvensional/cardStasiunTV.vue";
  import programStasiunTV from "@/components/analytic/general/media-konvensional/programStasiunTV.vue";
  import cardMediaCetak from "@/components/analytic/general/media-konvensional/cardMediaCetak.vue";
  import cardEmotion from "@/components/analytic/general/media-konvensional/cardEmotion.vue";
  import cardMedsos from "@/components/analytic/general/media-sosial/cardMedsos.vue";
  import cardChannelMedsos from "@/components/analytic/general/media-sosial/cardChannelMedsos.vue";
  import cardSentimenMedsos from "@/components/analytic/general/media-sosial/cardSentimenMedsos.vue";
  import cardTopikMedsos from "@/components/analytic/general/media-sosial/cardTopikMedsos.vue";
  import cardEmotionMedsos from "@/components/analytic/general/media-sosial/cardEmotionMedsos.vue";
  import cardHashtagMedsos from "@/components/analytic/general/media-sosial/cardHashtagMedsos.vue";
  import cardPeakTime from "@/components/analytic/general/media-sosial/cardPeakTime.vue";
  import cardStasiun from "@/components/analytic/general/media-sosial/cardStasiun.vue";
  
  export default {
    name: "General",
    components: {
      BCard,
      BButton,
      cardMedkon,
      trenGroupTopik,
      cardSentimen,
      cardTopikMedkon,
      cardStasiunTV,
      programStasiunTV,
      cardMediaCetak,
      cardEmotion,
      cardMedsos,
      cardChannelMedsos,
      cardSentimenMedsos,
      cardTopikMedsos,
      cardEmotionMedsos,
      cardHashtagMedsos,
      cardPeakTime,
      cardStasiun,
    },
  
    data() {
      return {
        title: "General | Online Media Monitoring",
        media: "konvensional",
      };
    },
  
    created() {
      document.title = this.title;
    },
  };
  </script>