<template>
  <b-card no-body class="h-100">
    <div class="row">
      <div class="col-md-4">
        <b-card no-body class="h-100">
          <b-card-header class="mb-2">
            <b-card-title>Data Topik</b-card-title>
            <!-- SWITCH BUTTON -->
            <b-form-checkbox
              v-model="checked"
              checked="true"
              class="custom-control-primary"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="PieChartIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="ServerIcon" />
              </span>
            </b-form-checkbox>
          </b-card-header>
          <!-- IF SWITCH BUTTON IS NOT CHECKED -->
          <div v-if="!checked">
            <!-- SEARCH FORM -->
            <b-input-group class="input-group-merge ml-auto mb-1 mr-2 w-50">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" class="text-primary" />
              </b-input-group-prepend>
              <b-form-input placeholder="Cari" v-model="table_data.filter" />
            </b-input-group>

            <!-- TABLE COMPONENT -->
            <dataTable :data="table_data" />
          </div>
          <b-card-body>
            <!-- IF SWITCH BUTTON IS CHECKED -->
            <div v-if="checked">
              <!-- PIE CHART COMPONENT -->
              <pieChart :data="pie_chart_data" />
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-8">
        <b-card no-body class="h-100">
          <b-card-header class="mb-2">
            <b-card-title>Tren Topik</b-card-title>
          </b-card-header>
          <b-card-body>
            <!-- LINE CHART COMPONENT -->
            <lineChart :data="chart_data" />
          </b-card-body>
        </b-card>
      </div>
    </div>
  </b-card>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
} from "bootstrap-vue";
import lineChart from "@/components/usable/lineChart.vue";
import dataTable from "@/components/usable/dataTable.vue";
import pieChart from "@/components/usable/pieChart.vue";
import { $themeColors } from "@themeConfig";

export default {
  name: "cardTopikMedkon",
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    lineChart,
    dataTable,
    pieChart,
  },

  data() {
    return {
      checked: false,
      table_data: {
        row: 5,
        filter: null,
        fields: [
          {
            key: "Topik",
            label: "Topik",
            sortable: true,
            thClass: "text-left",
            tdClass: "text-left",
          },
          {
            key: "Positif",
            label: "Positif",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "20%", paddingRight: "2rem" },
          },
          {
            key: "Netral",
            label: "Netral",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "20%", paddingRight: "2rem" },
          },
          {
            key: "Negatif",
            label: "Negatif",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "20%", paddingRight: "2rem" },
          },
        ],
        items: [
          {
            Topik: "Twitter",
            Positif: 511,
            Netral: 400,
            Negatif: 350,
          },
          {
            Topik: "Facebook",
            Positif: 250,
            Netral: 200,
            Negatif: 350,
          },
          {
            Topik: "Youtube",
            Positif: 511,
            Netral: 65,
            Negatif: 200,
          },
          {
            Topik: "Blog",
            Positif: 5,
            Netral: 5,
            Negatif: 500,
          },
          {
            Topik: "Tiktok",
            Positif: 340,
            Netral: 67,
            Negatif: 400,
          },
        ],
      },
      pie_chart_data: {
        colors: [
          $themeColors.success,
          $themeColors.primary,
          $themeColors.danger,
        ],
        legend: true,
        labels: ["Positif", "Netral", "Negatif"],
        series: [5, 6, 7],
      },
      chart_data: {
        colors: [
          $themeColors.success,
          $themeColors.primary,
          $themeColors.danger,
        ],
        categories: ["17", "18", "19", "20", "21", "22", "23"],
        series: [
          {
            name: "Positif",
            data: [45000, 47000, 47800, 49500, 45500, 48000, 46500, 48600],
          },
          {
            name: "Netral",
            data: [45000, 46000, 45500, 44600, 44500, 46500, 45000, 47000],
          },
          {
            name: "Negatif",
            data: [45000, 42000, 41500, 43600, 44500, 46500, 45000, 47000],
          },
        ],
      },
    };
  },
};
</script>
