<template>
  <b-card no-body class="h-100">
    <b-card-header class="mb-2">
      <b-card-title> Peak Time </b-card-title>
    </b-card-header>
    <b-card-body>
      <!-- HEAT MAP CHART COMPONENT -->
      <heatMapChart :data="chart_data" />
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BCardTitle } from "bootstrap-vue";
import heatMapChart from "@/components/usable/heatMapChart.vue";

export default {
  name: "cardPeakTime",
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    heatMapChart,
  },

  data() {
    return {
      chart_data: {
        series: [
          {
            name: "Sun",
            data: this.generateDataHeatMap(24, {
              min: 0,
              max: 60,
            }),
          },
          {
            name: "Mon",
            data: this.generateDataHeatMap(24, {
              min: 0,
              max: 60,
            }),
          },
          {
            name: "Tue",
            data: this.generateDataHeatMap(24, {
              min: 0,
              max: 60,
            }),
          },
          {
            name: "Wed",
            data: this.generateDataHeatMap(24, {
              min: 0,
              max: 60,
            }),
          },
          {
            name: "Thu",
            data: this.generateDataHeatMap(24, {
              min: 0,
              max: 60,
            }),
          },
          {
            name: "Fri",
            data: this.generateDataHeatMap(24, {
              min: 0,
              max: 60,
            }),
          },
          {
            name: "Sat",
            data: this.generateDataHeatMap(24, {
              min: 0,
              max: 60,
            }),
          },
        ],
      },
    };
  },

  methods: {
    generateDataHeatMap(count, yrange) {
      let i = 0;
      const series = [];
      while (i < count) {
        const x = `w${(i + 1).toString()}`;
        const y =
          Math.floor(Math.random() * (yrange.max - yrange.min + 1)) +
          yrange.min;

        series.push({
          x,
          y,
        });
        // eslint-disable-next-line no-plusplus
        i++;
      }
      return series;
    },
  },
};
</script>
