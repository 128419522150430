<template>
  <b-card no-body class="h-100">
    <b-card-header class="mb-2">
      <b-card-title class="ml-1"> Data Media Sosial</b-card-title>
    </b-card-header>
    <b-card-body>
      <b-row v-for="item in statisticsItems" :key="item.id" class="mb-1 ml-1">
        <b-media no-body>
          <b-media-aside class="mr-2">
            <!-- MEDIA AVATAR -->
            <b-avatar size="48" variant="light-primary">
              <!-- MENTION -->
              <i
                v-if="item.platform == 'mention'"
                class="bi bi-at fa-3x text-secondary"
              ></i>

              <!-- REACH -->
              <i
                v-if="item.platform == 'reach'"
                class="fa-solid fa-bullhorn fa-2x text-warning"
              ></i>

              <!-- ENGAGEMENT -->
              <i
                v-if="item.platform == 'engagement'"
                class="bi bi-share fa-2x text-info"
              ></i>
            </b-avatar>
          </b-media-aside>
          <b-media-body class="my-auto">
            <!-- VALUE -->
            <h4 class="font-weight-bolder mb-0">
              {{ item.title }}
            </h4>

            <!-- MEDIA NAME -->
            <b-card-text class="font-small-3 mb-0">
              {{ item.subtitle }}
            </b-card-text>
          </b-media-body>
        </b-media>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";

export default {
  name: "dataMedsos",
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  
  data() {
    return {
      statisticsItems: [
        {
          id: 1,
          platform: "mention",
          color: "light-primary",
          title: "230k",
          subtitle: "Mention",
        },
        {
          id: 2,
          platform: "reach",
          color: "light-info",
          title: "8.549k",
          subtitle: "Reach",
        },
        {
          id: 3,
          platform: "engagement",
          color: "light-success",
          title: "1.423k",
          subtitle: "Engagement",
        },
      ],
    };
  },
};
</script>
