<template>
  <b-card no-body class="h-100">
    <div class="row">
      <div class="col-md-4">
        <b-card no-body class="h-100">
          <b-card-header class="mb-2">
            <b-card-title>Data Stasiun TV</b-card-title>
            <!-- SEARCH FORM -->
            <b-input-group class="input-group-merge w-50">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" class="text-primary" />
              </b-input-group-prepend>
              <b-form-input placeholder="Cari" v-model="table_data.filter" />
            </b-input-group>
          </b-card-header>
          <!-- TABLE COMPONENT -->
          <dataTable :data="table_data" />
        </b-card>
      </div>
      <div class="col-md-8">
        <b-card no-body class="h-100">
          <b-card-header class="mb-2">
            <b-card-title>Tren Stasiun TV</b-card-title>
            <!-- SWITCH BUTTON -->
            <b-form-checkbox
              v-model="checked"
              checked="true"
              class="custom-control-primary"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="BarChart2Icon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="TrendingUpIcon" />
              </span>
            </b-form-checkbox>
          </b-card-header>
          <!-- IF SWITCH BUTTON IS NOT CHECKED -->
          <b-card-body v-if="!checked">
            <!-- LINE CHART COMPONENT -->
            <lineChart :data="chart_data" />
          </b-card-body>

          <!-- IF SWITCH BUTTON IS CHECKED -->
          <b-card-body v-if="checked">
            <!-- BAR CHART COMPONENT -->
            <dataBarChart :data="bar_chart_data" />
          </b-card-body>
        </b-card>
      </div>
    </div>
  </b-card>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
} from "bootstrap-vue";
import { $themeColors } from "@themeConfig";
import dataTable from "@/components/usable/dataTable.vue";
import lineChart from "@/components/usable/lineChart.vue";
import dataBarChart from "@/components/usable/dataBarChart.vue";

export default {
  name: "cardStasiunTV",
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    dataTable,
    lineChart,
    dataBarChart,
  },

  data() {
    return {
      checked: false,
      table_data: {
        row: 5,
        filter: null,
        fields: [
          {
            key: "Topik",
            label: "Topik",
            sortable: true,
            thClass: "text-left",
            tdClass: "text-left",
          },
          {
            key: "Positif",
            label: "Positif",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "20%", paddingRight: "2rem" },
          },
          {
            key: "Netral",
            label: "Netral",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "20%", paddingRight: "2rem" },
          },
          {
            key: "Negatif",
            label: "Negatif",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "20%", paddingRight: "2rem" },
          },
        ],
        items: [
          {
            Topik: "Twitter",
            Positif: 511,
            Netral: 400,
            Negatif: 350,
          },
          {
            Topik: "Facebook",
            Positif: 250,
            Netral: 200,
            Negatif: 350,
          },
          {
            Topik: "Youtube",
            Positif: 511,
            Netral: 65,
            Negatif: 200,
          },
          {
            Topik: "Blog",
            Positif: 5,
            Netral: 5,
            Negatif: 500,
          },
          {
            Topik: "Tiktok",
            Positif: 340,
            Netral: 67,
            Negatif: 400,
          },
        ],
      },
      bar_chart_data: {
        colors: ["#28DAC6"],
        categories: [
          "7/12",
          "8/12",
          "9/12",
          "10/12",
          "11/12",
          "12/12",
          "13/12",
          "14/12",
          "15/12",
          "16/12",
        ],
        series: [
          {
            name: "Positif",
            data: [90, 120, 55, 100, 80, 125, 175, 70, 88, 180],
          },
        ],
      },
      chart_data: {
        colors: [
          $themeColors.success,
          $themeColors.primary,
          $themeColors.danger,
        ],
        categories: ["17", "18", "19", "20", "21", "22", "23"],
        series: [
          {
            name: "Positif",
            data: [45000, 47000, 47800, 49500, 45500, 48000, 46500, 48600],
          },
          {
            name: "Netral",
            data: [45000, 46000, 45500, 44600, 44500, 46500, 45000, 47000],
          },
          {
            name: "Negatif",
            data: [45000, 42000, 41500, 43600, 44500, 46500, 45000, 47000],
          },
        ],
      },
    };
  },
};
</script>