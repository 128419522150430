<template>
  <b-card no-body class="h-100">
    <div class="row">
      <div class="col-md-9">
        <b-card no-body class="h-100">
          <b-card-header class="mb-2">
            <b-card-title>Tren Media Sosial</b-card-title>
          </b-card-header>
          <b-card-body>
            <!-- LINE CHART COMPONENT -->
            <lineChart :data="chart_data" />
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-3">
        <!-- DATA MEDIA SOSIAL COMPONENT -->
        <dataMedsos />
      </div>
    </div>
  </b-card>
</template>
<script>
import { BCard, BCardHeader, BCardBody, BCardTitle } from "bootstrap-vue";
import { $themeColors } from "@themeConfig";
import dataMedsos from "./dataMedsos.vue";
import lineChart from "@/components/usable/lineChart.vue";

export default {
  name: "cardMedsos",
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    dataMedsos,
    lineChart,
  },
  
  data() {
    return {
      checked: false,
      chart_data: {
        colors: [
          $themeColors.secondary,
          $themeColors.warning,
          $themeColors.info,
        ],
        categories: ["17", "18", "19", "20", "21", "22", "23"],
        series: [
          {
            name: "Mention",
            data: [45000, 47000, 47800, 49500, 45500, 48000, 46500, 48600],
          },
          {
            name: "Reach",
            data: [45000, 46000, 45500, 44600, 44500, 46500, 45000, 47000],
          },
          {
            name: "Engagement",
            data: [45000, 42000, 41500, 43600, 44500, 46500, 45000, 47000],
          },
        ],
      },
    };
  },
};
</script>