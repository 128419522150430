<template>
  <b-card no-body class="h-100">
    <b-card-header class="mb-2">
      <b-card-title>Wordcloud</b-card-title>
      <!-- SWITCH BUTTON -->
      <b-form-checkbox
        v-model="checked"
        checked="true"
        class="custom-control-primary"
        name="check-button"
        switch
      >
        <span class="switch-icon-left">
          <feather-icon icon="ServerIcon" />
        </span>
        <span class="switch-icon-right">
          <feather-icon icon="TypeIcon" />
        </span>
      </b-form-checkbox>
    </b-card-header>
    <b-card-body>
      <!-- IF SWITCH BUTTON IS NOT CHECKED -->
      <div v-if="!checked">
        <!-- WORDCLOUD COMPONENT -->
        <wordCloud :items="table_data.items" />
      </div>

      <!-- IF SWITCH BUTTON IS CHECKED -->
      <div v-else style="margin-left: 20rem; margin-right: 20rem">
        <!-- SEARCH FORM -->
        <b-input-group class="input-group-merge w-25 ml-auto mb-1">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" class="text-primary" />
          </b-input-group-prepend>
          <b-form-input placeholder="Cari" v-model="table_data.filter" />
        </b-input-group>

        <!-- TABLE COMPONENT -->
        <dataTable :data="table_data" />
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BCardText,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
} from "bootstrap-vue";
import wordCloud from "@/components/usable/wordCloud.vue";
import dataTable from "@/components/usable/dataTable.vue";

export default {
  name: "cardStasiun",
  components: {
    BCardText,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    wordCloud,
    dataTable,
  },

  data() {
    return {
      checked: false,
      table_data: {
        row: 10,
        filter: null,
        fields: [
          {
            key: "kata",
            label: "Kata",
            thClass: "text-left",
            tdClass: "text-left",
            sortable: true,
          },
          {
            key: "frekuensi",
            label: "Frekuensi",
            thClass: "text-center",
            tdClass: "text-center",
            sortable: true,
            thStyle: { width: "30%", paddingRight: "2rem" },
          },
        ],
        items: [
          {
            kata: "Kemudian",
            frekuensi: 26,
          },
          {
            kata: "fish",
            frekuensi: 19,
          },
          {
            kata: "things",
            frekuensi: 18,
          },
          {
            kata: "look",
            frekuensi: 16,
          },
          {
            kata: "two",
            frekuensi: 15,
          },
          {
            kata: "fun",
            frekuensi: 9,
          },
          {
            kata: "know",
            frekuensi: 9,
          },
          {
            kata: "good",
            frekuensi: 9,
          },
          {
            kata: "play",
            frekuensi: 6,
          },
          {
            kata: "Lebaran",
            frekuensi: 6,
          },
          {
            kata: "Idul Fitri",
            frekuensi: 6,
          },
          {
            kata: "Indonesia",
            frekuensi: 6,
          },
          {
            kata: "Hanhan",
            frekuensi: 6,
          },
          {
            kata: "Ketupat",
            frekuensi: 6,
          },
          {
            kata: "Laptop",
            frekuensi: 6,
          },
          {
            kata: "Kriminal",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Bermain",
            frekuensi: 6,
          },
          {
            kata: "Kemana",
            frekuensi: 6,
          },
          {
            kata: "Merdeka",
            frekuensi: 6,
          },
          {
            kata: "German",
            frekuensi: 6,
          },
          {
            kata: "Handphone",
            frekuensi: 6,
          },
          {
            kata: "Charger",
            frekuensi: 6,
          },
          {
            kata: "Buku",
            frekuensi: 6,
          },
          {
            kata: "Idul Adha",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
          {
            kata: "Semua",
            frekuensi: 6,
          },
        ],
      },
    };
  },
};
</script>
