<template>
  <wordcloud
    :data="items"
    nameKey="kata"
    valueKey="frekuensi"
    :color="color"
    :wordPadding="padding"
    :showTooltip="false"
    font="arial"
  >
  </wordcloud>
</template>
<script>
import wordcloud from "vue-wordcloud";

export default {
  name: "wordCloud",
  components: {
    wordcloud,
  },

  props: ["items"],

  data() {
    return {
      color: [
        "#1f77b4",
        "#629fc9",
        "#94bedb",
        "#c9e0ef",
        "#7FFF00",
        "#00FFFF",
        "#FF8C00",
        "#FFD700",
        "#ADFF2F",
        "#B0C4DE",
        "#FF0000",
        "#FA8072",
        "#87CEEB",
        "#FFFF00",
        "#EE82EE",
        "#FFC0CB",
        "#808000",
      ],
      padding: 2,
    };
  },

  created(){
    document.querySelectorAll(".tooltip").forEach(el => el.remove());
  }
};
</script>