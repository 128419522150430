<template>
  <vue-apex-charts
    type="heatmap"
    height="370"
    :options="config"
    :series="data.series"
    class="clickable"
  />
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "heatMapChart",
  components: {
    VueApexCharts,
  },

  props: {
    data: Object,
  },

  data() {
    return {
      config: {
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
          position: "bottom",
        },
        colors: ["#7367F0"],
        plotOptions: {
          heatmap: {
            enableShades: false,

            colorScale: {
              ranges: [
                {
                  from: 0,
                  to: 10,
                  name: "0-10",
                  color: "#b9b3f8",
                },
                {
                  from: 11,
                  to: 20,
                  name: "10-20",
                  color: "#aba4f6",
                },
                {
                  from: 21,
                  to: 30,
                  name: "20-30",
                  color: "#9d95f5",
                },
                {
                  from: 31,
                  to: 40,
                  name: "30-40",
                  color: "#8f85f3",
                },
                {
                  from: 41,
                  to: 50,
                  name: "40-50",
                  color: "#8176f2",
                },
                {
                  from: 51,
                  to: 60,
                  name: "50-60",
                  color: "#7367f0",
                },
              ],
            },
          },
        },
        xaxis: {
          labels: {
            show: true,
            style: {
              colors: [
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
              ],
              fontSize: "12px",
              fontFamily: "Montserrat",
              fontWeight: 600,
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: [
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
                "#A9A9A9",
              ],
              fontSize: "12px",
              fontFamily: "Montserrat",
              fontWeight: 600,
            },
          },
        },
        chart: {
          height: 350,
          type: "heatmap",
          toolbar: {
            show: false,
          },
        },
      },
    };
  },
};
</script>
