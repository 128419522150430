<template>
  <b-card no-body class="h-100">
    <b-card-header class="mb-2">
      <b-card-title> Program Stasiun TV </b-card-title>
    </b-card-header>
    <b-card-body>
      <!-- BAR CHART COMPONENT -->
      <dataBarChart :data="chart_data" />
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BCardTitle } from "bootstrap-vue";
import dataBarChart from "@/components/usable/dataBarChart.vue";

export default {
  name: "programStasiunTV",
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    dataBarChart,
  },

  data() {
    return {
      chart_data: {
        colors: ["#28DAC6"],
        categories: [
          "7/12",
          "8/12",
          "9/12",
          "10/12",
          "11/12",
          "12/12",
          "13/12",
          "14/12",
          "15/12",
          "16/12",
        ],
        series: [
          {
            name: "Positif",
            data: [90, 120, 55, 100, 80, 125, 175, 70, 88, 180],
          },
        ],
      },
    };
  },
};
</script>
