<template>
  <b-card no-body class="h-100">
    <div class="row">
      <div class="col-md-4">
        <b-card no-body class="h-100">
          <b-card-header class="mb-2">
            <b-card-title>Presentase Sentimen</b-card-title>
          </b-card-header>
          <b-card-body>
            <!-- PIE CHART COMPONENT -->
            <pieChart :data="pie_chart_data" />
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-8">
        <b-card no-body class="h-100">
          <b-card-header class="mb-2">
            <b-card-title>Tren Sentimen</b-card-title>
          </b-card-header>
          <b-card-body>
            <!-- LINE CHART COMPONENT -->
            <lineChart :data="chart_data" />
          </b-card-body>
        </b-card>
      </div>
    </div>
  </b-card>
</template>
<script>
import { BCard, BCardHeader, BCardBody, BCardTitle } from "bootstrap-vue";
import { $themeColors } from "@themeConfig";
import pieChart from "@/components/usable/pieChart.vue";
import lineChart from "@/components/usable/lineChart.vue";

export default {
  name: "cardSentimenMedsos",
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    pieChart,
    lineChart,
  },

  data() {
    return {
      pie_chart_data: {
        colors: [
          $themeColors.success,
          $themeColors.primary,
          $themeColors.danger,
        ],
        legend: true,
        labels: ["Positif", "Netral", "Negatif"],
        series: [5, 6, 7],
      },
      chart_data: {
        colors: [
          $themeColors.success,
          $themeColors.primary,
          $themeColors.danger,
        ],
        categories: ["17", "18", "19", "20", "21", "22", "23"],
        series: [
          {
            name: "Positif",
            data: [45000, 47000, 47800, 49500, 45500, 48000, 46500, 48600],
          },
          {
            name: "Netral",
            data: [45000, 46000, 45500, 44600, 44500, 46500, 45000, 47000],
          },
          {
            name: "Negatif",
            data: [45000, 42000, 41500, 43600, 44500, 46500, 45000, 47000],
          },
        ],
      },
    };
  },
};
</script>