<template>
  <b-card no-body class="h-100">
    <div class="row">
      <div class="col-md-9">
        <b-card no-body class="h-100">
          <b-card-header class="mb-2">
            <b-card-title>Tren Media Konvensional</b-card-title>
            <!-- SWITCH BUTTON -->
            <b-form-checkbox
              v-model="checked"
              checked="true"
              class="custom-control-primary"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="BarChart2Icon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="TrendingUpIcon" />
              </span>
            </b-form-checkbox>
          </b-card-header>
          <b-card-body>
            <!-- IF SWITCH BUTTON IS CHECKED -->
            <div v-if="checked">
              <!-- BAR CHART COMPONENT -->
              <dataBarChart :data="chart_data"/>
            </div>

            <!-- IF SWITCH BUTTON IS NOT CHECKED -->
            <div v-if="!checked">
              <!-- LINE CHART COMPONENT -->
              <lineChart :data="chart_data" />
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-3">
        <!-- DATA MEDIA KONVENSIONAL COMPONENT -->
        <dataMedkon />
      </div>
    </div>
  </b-card>
</template>
<script>
import dataMedkon from "./dataMedkon.vue";
import lineChart from "@/components/usable/lineChart.vue";
import dataBarChart from "@/components/usable/dataBarChart.vue";
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BFormCheckbox,
} from "bootstrap-vue";

export default {
  name: "cardMedkon",
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BFormCheckbox,
    dataMedkon,
    lineChart,
    dataBarChart,
  },

  data() {
    return {
      checked: false,
      chart_data: {
        colors: ["#28C76F", "#82868B", "#E83E8C"],
        categories: ["17", "18", "19", "20", "21", "22", "23","24"],
        series: [
          {
            name: "Media Online",
            data: [45000, 47000, 47800, 49500, 45500, 48000, 46500, 48600],
          },
          {
            name: "Media Cetak",
            data: [45000, 46000, 45500, 44600, 44500, 46500, 45000, 47000],
          },
          {
            name: "Media Elektronik",
            data: [45000, 42000, 41500, 43600, 44500, 46500, 45000, 47000],
          },
        ],
      },
    };
  },
};
</script>