<template>
  <b-card no-body class="h-100">
    <b-card-header class="mb-2">
      <b-card-title class="ml-1"> Data Media Konvensional</b-card-title>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row v-for="item in statisticsItems" :key="item.id" class="mb-1 ml-1">
        <b-media no-body>
          <b-media-aside class="mr-2">
            <!-- MEDIA AVATAR -->
            <b-avatar size="48" variant="light-primary">
              <!-- MEDIA CETAK -->
              <i
                v-if="item.platform == 'media cetak'"
                class="bi bi-newspaper media-cetak-color fa-2x"
              ></i>

              <!-- MEDIA ONLINE -->
              <i
                v-if="item.platform == 'media online'"
                class="bi bi-globe media-online-color fa-2x"
              ></i>

              <!-- MEDIA ELEKTRONIK -->
              <i
                v-if="item.platform == 'media elektronik'"
                class="bi bi-display media-elektronik-color fa-2x"
              ></i>
            </b-avatar>
          </b-media-aside>
          <b-media-body class="my-auto">
            <!-- VALUE -->
            <h4 class="font-weight-bolder mb-0">
              {{ item.title }}
            </h4>

            <!-- MEDIA NAME -->
            <b-card-text class="font-small-3 mb-0">
              {{ item.subtitle }}
            </b-card-text>
          </b-media-body>
        </b-media>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";

export default {
  name: "dataMedkon",
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  
  data() {
    return {
      statisticsItems: [
        {
          id: 1,
          platform: "media online",
          title: "230k",
          subtitle: "Media Online",
        },
        {
          id: 2,
          platform: "media cetak",
          title: "8.549k",
          subtitle: "Media Cetak",
        },
        {
          id: 3,
          platform: "media elektronik",
          title: "1.423k",
          subtitle: "Media Elektronik",
        },
      ],
    };
  },
};
</script>
