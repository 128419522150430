<template>
  <b-card no-body class="h-100">
    <b-card-header class="mb-2">
      <b-card-title>Tren Group Topik</b-card-title>
      <!-- DOWNLOAD BUTTON -->
      <b-button variant="outline-primary">
        <feather-icon icon="DownloadIcon" class="mr-50" />
        <span class="align-middle">Download</span>
      </b-button>
    </b-card-header>
    <b-card-body>
      <!-- LINE CHART COMPONENT -->
      <lineChart :data="chart_data" />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BCardText,
  BButton,
} from "bootstrap-vue";
import lineChart from "@/components/usable/lineChart.vue";

export default {
  name: "trenGroupTopik",
  components: {
    lineChart,
    BCardText,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
  },
  
  data() {
    return {
      chart_data: {
        colors: ["#28C76F", "#82868B", "#E83E8C"],
        categories: ["17", "18", "19", "20", "21", "22", "23"],
        series: [
          {
            name: "Media Online",
            data: [45000, 47000, 47800, 49500, 45500, 48000, 46500, 48600],
          },
          {
            name: "Media Cetak",
            data: [45000, 46000, 45500, 44600, 44500, 46500, 45000, 47000],
          },
          {
            name: "Media Elektronik",
            data: [45000, 42000, 41500, 43600, 44500, 46500, 45000, 47000],
          },
        ],
      },
    };
  },
};
</script>
