<template>
  <b-card no-body class="h-100">
    <div class="row">
      <div class="col-md-4">
        <b-card no-body class="h-100">
          <b-card-header class="mb-2">
            <b-card-title>Presentase Emotion</b-card-title>
          </b-card-header>
          <b-card-body>
            <!-- PIE CHART COMPONENT -->
            <pieChart :data="pie_chart_data" />
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-8">
        <b-card no-body class="h-100">
          <b-card-header class="mb-2">
            <b-card-title>Tren Emotion</b-card-title>
          </b-card-header>
          <b-card-body>
            <!-- LINE CHART COMPONENT -->
            <lineChart :data="chart_data" />
          </b-card-body>
        </b-card>
      </div>
    </div>
  </b-card>
</template>
<script>
import { BCard, BCardHeader, BCardBody, BCardTitle } from "bootstrap-vue";
import pieChart from "@/components/usable/pieChart.vue";
import lineChart from "@/components/usable/lineChart.vue";

export default {
  name: "cardEmotionMedsos",
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    pieChart,
    lineChart,
  },

  data() {
    return {
      pie_chart_data: {
        colors: ["#82868B", "#28C76F", "#FF9F43", "#F26322", "#EA5455"],
        legend: true,
        labels: ["No Emotion", "Senang", "Takut", "Sedih", "Marah"],
        series: [2, 2, 5, 8, 9],
      },
      chart_data: {
        colors: ["#82868B", "#28C76F", "#FF9F43", "#F26322", "#28C76F"],
        categories: ["17", "18", "19", "20", "21", "22", "23"],
        series: [
          {
            name: "No Emotion",
            data: [45000, 42000, 41500, 43600, 49500, 46500, 45000, 47000],
          },
          {
            name: "Senang",
            data: [45000, 46000, 46500, 47600, 47500, 46500, 45000, 47000],
          },
          {
            name: "Takut",
            data: [45000, 46000, 47500, 43600, 44500, 46500, 45000, 47000],
          },
          {
            name: "Sedih",
            data: [45000, 42000, 42500, 44600, 44500, 46500, 45000, 47000],
          },
          {
            name: "Marah",
            data: [45000, 41000, 41500, 43600, 44500, 46500, 45000, 47000],
          },
        ],
      },
    };
  },
};
</script>